module.exports = {
    siteTitle               : 'Commonality.me', // <title>
    shortSiteTitle          : 'Commonality', // <title> ending for posts and pages
    siteDescription         :
        'Smart Tools and Support to Hack Your Way Back to Health',
    siteUrl                 : 'https://commonality.me',
    pathPrefix              : '/',
    siteImage               : 'https://s3.us-east-2.amazonaws.com/commonality.me/preview.png',
    siteLanguage            : 'en',

    /* author */
    authorName              : 'Nigel Gordon',
    authorTwitterAccount    : 'nigelgordon',

    /* info */
    headerTitle             : 'Commonality',
    // headerSubTitle: "presents another one GatsbyJS starter",

    /* manifest.json */
    manifestName            : 'Commonality.me hack your way back to health',
    manifestShortName       : 'Commonality.me', // max 12 characters
    manifestStartUrl        : '/index.html',
    manifestBackgroundColor : 'white',
    manifestThemeColor      : '#666',
    manifestDisplay         : 'standalone',

    // gravatar
    // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
    // Replace your email adress with md5-code.
    // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
    // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
    gravatarImgMd5          : '',

    // social
    authorSocialLinks       : [
        // { name: "github", url: "https://github.com/greglobinski" },
        { name: 'twitter', url: 'https://twitter.com/nigelgordon' }
        // { name: "facebook", url: "http://facebook.com/nig" }
    ]
};
